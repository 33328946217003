import {
  useListUsersQuery,
  useGetProactiveScamEnablerAssignUsersQuery,
  useGetScamEnablerAssignUsersQuery,
} from '@api/userSlice';
import Dropdown from './dropdown/Dropdown';
import { User } from '@interface/UsersInterface';

const DropdownUser = ({
  id,
  control,
  setValue,
  userGroup,
  children,
}: {
  id: string;
  control: any;
  setValue: any;
  userGroup: any;
  children?: any;
}) => {

  const { data: paymentDailyReturnsModalUsers } = useListUsersQuery(
    {
      params: {
        active: true
      },
    },
    { skip: userGroup !== 'paymentDailyReturnsModalUsers' },
  );
  const { data: scamEnablerUsersData } = useGetScamEnablerAssignUsersQuery(
    {
      params: {},
    },
    { skip: userGroup !== 'scamEnablers' },
  );
  const { data: proactiveScamEnablerUsersData } = useGetProactiveScamEnablerAssignUsersQuery(
    {
      params: {},
    },
    { skip: userGroup !== 'proactiveScamEnablers' },
  );

  return (
    <div className="w-full">
      {paymentDailyReturnsModalUsers?.users && userGroup === 'paymentDailyReturnsModalUsers' && (
        <Dropdown
          id={id}
          control={control}
          dropdownData={paymentDailyReturnsModalUsers.users.map((user: User) => {
            return {
              id: user?.email,
              label: `${user?.firstName} ${user?.lastName}`,
              subLabel: user?.email,
            };
          })}
          setValue={setValue}
        >
          {children}
        </Dropdown>
      )}
      {scamEnablerUsersData?.users && userGroup === 'scamEnablers' && (
        <Dropdown
          id={id}
          control={control}
          dropdownData={scamEnablerUsersData.users.map((user: User) => {
            return {
              id: user.userId,
              label: `${user.firstName} ${user.lastName}`,
              subLabel: user.email,
            };
          })}
          setValue={setValue}
        >
          {children}
        </Dropdown>
      )}
      {proactiveScamEnablerUsersData?.users && userGroup === 'proactiveScamEnablers' && (
        <Dropdown
          id={id}
          control={control}
          dropdownData={proactiveScamEnablerUsersData.users.map((user: User) => {
            return {
              id: user.userId,
              label: `${user.firstName} ${user.lastName}`,
              subLabel: user.email,
            };
          })}
          setValue={setValue}
        >
          {children}
        </Dropdown>
      )}
    </div>
  );
};

export default DropdownUser;
