import { Modal } from '@mui/material';
import ModalLayout from '@components/layouts/ModalLayout';
import { useEffect, useMemo, useState } from 'react';
import { CommonButton } from '@components/common/CommonButton';
import {
  useDownloadBankSmsCSV,
  useDownloadMonikerStatistics,
  useDownloadProactiveScamEnablerCSV,
  useDownloadScamEnablerCSV,
} from '@api/scamEnablerSlice';
import { useDownloadDailyReturns } from '@api/productionOrderSlice';
import { ErrorOutline } from '@mui/icons-material';
import DateRangePicker from '@components/common/DateRangePicker';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import RadioGroupComponent from '@components/common/RadioGroup';
import DropdownUser from '@components/common/DropdownUser';
import { getSessionUserFromAccessToken } from '@utils/utils';

const DownloadDateRangeModal = ({
  isOpen,
  onClose,
  downloadType,
}: {
  isOpen: boolean;
  onClose: () => void;
  downloadType: string;
}) => {
  const [user, setUser] = useState<any>();
  const defaultValues = useMemo(() => {
    const initialValues: any = {};
    const currentTime = dayjs();

    let date_from, date_to;
    if (downloadType === 'paymentDailyReturns') {
      const today0800 = currentTime.hour(8).minute(0).second(0);

      if (currentTime.isBefore(today0800)) {
        date_from = today0800.add(-2, 'day').format('YYYY-MM-DD HH:mm');
        date_to = today0800.add(-1, 'day').format('YYYY-MM-DD HH:mm');
      } else {
        date_from = today0800.add(-1, 'day').format('YYYY-MM-DD HH:mm');
        date_to = today0800.format('YYYY-MM-DD HH:mm');
      }

      initialValues['date_from,date_to'] = [date_from, date_to];
      initialValues['paymentDailyReturnsDo'] = user?.email;
    } else if (downloadType === 'dailyReturns') {
      const today1230 = currentTime.hour(12).minute(0).second(0);
      const today1800 = currentTime.hour(18).minute(0).second(0);

      if (currentTime.isBefore(today1230)) {
        date_from = today1230.add(-1, 'day').format('YYYY-MM-DD HH:mm');
        date_to = today1800.add(-1, 'day').format('YYYY-MM-DD HH:mm');
      } else if (currentTime.isAfter(today1800)) {
        date_from = today1230.format('YYYY-MM-DD HH:mm');
        date_to = today1800.format('YYYY-MM-DD HH:mm');
      } else {
        date_from = today1800.add(-1, 'day').format('YYYY-MM-DD HH:mm');
        date_to = today1230.format('YYYY-MM-DD HH:mm');
      }

      initialValues['date_from,date_to'] = [date_from, date_to];
    } else if (
      downloadType === 'monikers' ||
      downloadType === 'monikerStatistics' ||
      downloadType === 'proactiveStatistics' ||
      downloadType === 'bankSms'
    ) {
      date_from = currentTime
        .add(-1, 'month')
        .startOf('month')
        .hour(0)
        .minute(0)
        .second(0)
        .format('YYYY-MM-DD HH:mm');
      date_to = currentTime.startOf('month').hour(0).minute(0).second(0).format('YYYY-MM-DD HH:mm');
      // date_to = currentTime.day(1).hour(0).minute(0).second(0).format('YYYY-MM-DD HH:mm');
      initialValues['date_from,date_to'] = [date_from, date_to];
    }
    return initialValues;
  }, [downloadType, user]);

  const formMethods = useForm({
    defaultValues,
    mode: 'onChange',
  });

  useEffect(() => {
    const getUserData = async () => {
      const sessionUser = await getSessionUserFromAccessToken();
      setUser(sessionUser);
    };

    getUserData();
  }, []);

  useEffect(() => {
    formMethods.reset(defaultValues); // This will reset the form with new default values
  }, [defaultValues, formMethods]);

  const [isLoading, setIsLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [monikerStatisticsType, setMonikerStatisticsType] = useState('submissions');

  const downloadScamEnblerStatistics = useDownloadScamEnablerCSV();
  const downloadDailyReturns = useDownloadDailyReturns();
  const downloadProactiveScamEnblerStatistics = useDownloadProactiveScamEnablerCSV();
  const downloadBankSmsCSV = useDownloadBankSmsCSV();

  const downloadMonikerStatistics = useDownloadMonikerStatistics();

  const handleClick = async () => {
    setIsLoading(true);
    let values = formMethods.getValues();
    let dateRange = values['date_from,date_to'];

    if (downloadType === 'monikers') {
      let response = await downloadScamEnblerStatistics({
        date_from: Date.parse(dateRange[0]),
        date_to: Date.parse(dateRange[1]),
      });
    }
    if (downloadType === 'monikerStatistics') {
      let response = await downloadMonikerStatistics({
        date_from: Date.parse(dateRange[0]),
        date_to: Date.parse(dateRange[1]),
        type: monikerStatisticsType,
      });
    } else if (downloadType === 'proactiveStatistics') {
      let response = await downloadProactiveScamEnblerStatistics({
        date_from: Date.parse(dateRange[0]),
        date_to: Date.parse(dateRange[1]),
      });
    } else if (downloadType === 'bankSms') {
      let response = await downloadBankSmsCSV({
        date_from: Date.parse(dateRange[0]),
        date_to: Date.parse(dateRange[1]),
      });
    } else if (downloadType === 'dailyReturns') {
      let response = await downloadDailyReturns(
        Date.parse(dateRange[0]),
        Date.parse(dateRange[1]),
        'OCHA',
      );
    } else if (downloadType === 'paymentDailyReturns') {
      let response = await downloadDailyReturns(
        Date.parse(dateRange[0]),
        Date.parse(dateRange[1]),
        'payment',
        values['paymentDailyReturnsDo'],
      );
    }
    setIsLoading(false);
  };

  const getModalTitle = () => {
    let title = '';

    if (downloadType === 'monikerStatistics' || downloadType === 'proactiveStatistics') {
      title = 'Statistics';
    } else if (downloadType === 'bankSms') {
      title = 'SMS';
    } else if (downloadType === 'monikers') {
      title = 'Monikers';
    } else {
      title = 'Daily Returns';
    }

    return title;
  };

  const getDateRangeTitle = () => {
    let title = 'Date Range';

    if (downloadType === 'proactiveStatistics' || downloadType === 'bankSms') {
      title = 'Date Received';
    } else if (downloadType === 'monikers') {
      title = 'Date of Final Outcome';
    } else if (downloadType === 'monikerStatistics') {
      if (monikerStatisticsType == 'submissions') {
        title = 'Date of Creation';
      } else {
        title = 'PO Sent Date';
      }
    } else {
      title = 'PO Sent Date';
    }

    return title;
  };

  const radioItems = [
    {
      value: 'submissions',
      label: `Date Received (Submissions)`,
    },
    {
      value: 'productionOrders',
      label: `PO Created Date`,
    },
  ];

  const handleOptionChange = (event: any) => {
    setMonikerStatisticsType(event.target.value);
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <ModalLayout
          title={`Download ${getModalTitle()}`}
          handleCloseModal={onClose}
          alignment="center"
          size="small"
          overflowClass="overflow-hidden"
        >
          <>
            {downloadType === 'monikerStatistics' && (
              <>
                <p className="pb-2">Download By:</p>
                <RadioGroupComponent
                  radioItems={radioItems}
                  onChange={handleOptionChange}
                  defaultValue={'submissions'}
                />
              </>
            )}
            {downloadType === 'paymentDailyReturns' && (
              <div className='pb-4'>
                <p className="font-bold  pb-2">DO:</p>
                <DropdownUser
                  id={'paymentDailyReturnsDo'}
                  userGroup={'paymentDailyReturnsModalUsers'}
                  control={formMethods.control}
                  setValue={formMethods.setValue}
                />
              </div>
            )}
            <p className="pb-2">Please specify the date range:</p>
            <p className="font-bold pb-2">{`${getDateRangeTitle()}`}</p>
            <form>
              <DateRangePicker
                id={'date_from,date_to'}
                control={formMethods.control}
                formState={formMethods.formState}
                setValue={formMethods.setValue}
                showTime={true}
              ></DateRangePicker>
            </form>
            {showErrorMessage && errorMessage !== '' && (
              <div className={`flex flex-row justify-end`}>
                <ErrorOutline className="mr-2" />
                <div className="flex flex-col">
                  <p className="text-grey-400 text-sm italic ">{errorMessage}</p>
                </div>
              </div>
            )}
            <div className="flex justify-end gap-4 pt-8">
              <CommonButton isDisabled={false} variant="text" title={`Cancel`} onClick={onClose} />
              <CommonButton
                isLoading={isLoading}
                variant="primary"
                title="Download"
                isDisabled={false}
                onClick={handleClick}
                onMouseEnter={() => {
                  setShowErrorMessage(true);
                }}
                onMouseLeave={() => setShowErrorMessage(false)}
              />
            </div>
          </>
        </ModalLayout>
      </Modal>
    </>
  );
};

export default DownloadDateRangeModal;
